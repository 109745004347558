import { Marker, Popup } from 'react-leaflet'
import React from 'react'

import L from 'leaflet'
import iconUrl from 'src/assets/icons/map-marker.svg'
const markerIcon = L.icon({
  iconUrl,
  iconSize: [40, 40],
})

const CustomMarker = ({ coordinates: { latitude, longitude, altitude = 0 } }) =>
  latitude && longitude ? (
    <Marker
      position={{
        lat: latitude,
        lng: longitude,
        alt: altitude,
      }}
      icon={markerIcon}
    >
      <Popup>{`lat: ${latitude}, lng: ${longitude}, alt: ${altitude}`}</Popup>
    </Marker>
  ) : null

export default CustomMarker
