import { Font } from '@react-pdf/renderer'

import RobotoBold from 'src/assets/fonts/Roboto-Bold.ttf'
import RobotoLight from 'src/assets/fonts/Roboto-Light.ttf'
import RobotoRegular from 'src/assets/fonts/Roboto-Regular.ttf'

import RobotoArabicRegular from 'src/assets/fonts/RobotoArabic-Regular.ttf'

/** needs to be called before any react-pdf including this font is rendered */

export default (): void => {
  Font.register({
    family: 'Roboto',
    fonts: [
      { src: RobotoBold, fontWeight: 'bold' },
      { src: RobotoLight, fontWeight: 'light' },
      { src: RobotoRegular },
    ],
  })
  Font.register({
    family: 'RobotoArabic',
    fonts: [{ src: RobotoArabicRegular }],
  })
}
